import { gql } from '@apollo/client';

/** 아이디, 닉네임 중복 체크 */
export const CHECK_DUPICATED_USER_INFO = gql`
  query ($checkType: CheckDuplicatedType!, $value: String!) {
    checkDuplicatedUserInfo(
      checkType: $checkType
      value: $value
    ) {
      result
      errCode
    }
  }
`;

/** 이메일 인증코드 발송 */
export const EMAIL_VERIFICATION = gql`
  query ($email: String!, $lang: Language!, $checkDuplicate: Boolean!) {
    emailVerification(
      email: $email
      lang: $lang
      checkDuplicate: $checkDuplicate
    ) {
      result
      errCode
    }
  }
`;

/** 이메일 인증코드 확인 */
export const CHECK_EMAIL_AUTHCODE = gql`
  mutation ($email: String!, $authCode: String!, $uuid: String!) {
    checkEmailAuthCode(
      email: $email
      authCode: $authCode
      uuid: $uuid
    ) {
      result {
        success
        userId
      }
      errCode
    }
  }
`;

/** 휴대전화 인증코드 발송 */
export const PHONE_VERIFICATION = gql`
  query ($countryCode: String!, $phone: String!, $lang: Language!, $checkDuplicate: Boolean!) {
    phoneVerification(
      countryCode: $countryCode
      phone: $phone
      lang: $lang
      checkDuplicate: $checkDuplicate
    ) {
      result
      errCode
    }
  }
`;

/** 휴대전화 인증코드 확인 */
export const CHECK_PHONE_AUTHCODE = gql`
  mutation ($countryCode: String!, $phone: String!, $authCode: String!, $uuid: String!) {
    checkPhoneAuthCode(
      countryCode: $countryCode
      phone: $phone
      authCode: $authCode
      uuid: $uuid
    ) {
      result {
        success
        userId
      }
      errCode
    }
  }
`;

/** 회원가입 */
export const SIGN_UP = gql`
  mutation ($userInfo: SignUpUserInfoInput!, $uuid: SignUpUuidInput!) {
    signUp(
      userInfo: $userInfo
      uuid: $uuid
    ) {
      result
      errCode
    }
  }
`;

/** 이름, 이메일 중복 확인 (계정 찾기) */
export const CHECK_DUPLICATED_NAME_AND_EMAIL = gql`
  query ($name: String!, $email: String!) {
    checkDuplicatedNameAndEmail(
      name: $name
      email: $email
    ) {
      result
      errCode
    }
  }
`;

/** 이름, 국가코드, 휴대전화번호 중복 확인 (계정 찾기) */
export const CHECK_DUPLICATED_NAME_AND_PHONE = gql`
  query ($name: String!, $countryCode: String!, $phone: String!) {
    checkDuplicatedNameAndPhone(
      name: $name
      countryCode: $countryCode
      phone: $phone
    ) {
      result
      errCode
    }
  }
`;

/** 비밀번호 초기화 */
export const RESET_PASSWORD = gql`
  mutation ($email: String, $countryCode: String, $phone: String, $password: String!, $uuid: String!) {
    resetPassword(
      email: $email
      countryCode: $countryCode
      phone: $phone
      password: $password
      uuid: $uuid
    ) {
      result
      errCode
    }
  }
`;

/** 로그인 */
export const LOGIN = gql`
  query ($userId: String!, $password: String!) {
    login(
      userId: $userId
      password: $password
    ) {
      result {
        userInfo {
          uuid
          userId
          name
          nickname
          email
          countryCode
          phone
          birth
          profileImage
          profileText
          friends
          rooms
          registDate
        }
        token {
          accessToken
          refreshToken
        }
        banDate
        unbanDate
      }
      errCode
    }
  }
`;

/** 유저 프로필 수정 */
export const MODIFY_USER_PROFILE = gql`
  mutation ($nickname: String, $profileImage: String, $profileText: String) {
    modifyUserProfile(
      nickname: $nickname
      profileImage: $profileImage
      profileText: $profileText
    ) {
      result {
        uuid
        userId
        name
        nickname
        countryCode
        phone
        birth
        profileImage
        profileText
        friends
        rooms
        registDate
      }
      errCode
    }
  }
`;

/** 유저 정보 수정 */
export const MODIFY_USER_INFO = gql`
  mutation ($userInfo: ModifyUserInfoInput, $authType: ModifyUserAuthType!, $uuid: ModifyUserUuidInput) {
    modifyUserInfo(
      userInfo: $userInfo
      authType: $authType
      uuid: $uuid
    ) {
      result {
        userId
        name
        nickname
        email
        countryCode
        phone
        birth
        profileImage
        profileText
        friends
        rooms
        registDate
      }
      errCode
    }
  }
`;

/** Access Token 갱신 */
export const REFRESH_ACCESS_TOKEN = gql`
  query ($uuid: String!) {
    refreshAccessToken(
      uuid: $uuid
    ) {
      result
      errCode
    }
  }
`;

/** 회원탈퇴 */
export const DELETE_USER = gql`
  mutation {
    deleteUser {
      result
      errCode
    }
  }
`;

/** 유저별 공지 조회 */
export const GET_NOTICES = gql`
  query {
    getNotices {
      result {
        uuid
        noticeTitle
        noticeTitleEn
        noticeContents
        noticeContentsEn
        noticeStartDate
        noticeEndDate
      }
      errCode
    }
  }
`;

/** 공지 다시 보지 않기 */
export const READ_NOTICE = gql`
  mutation ($noticeUuid: String!) {
    readNotice(noticeUuid: $noticeUuid) {
      result
      errCode
    }
  }
`;

/** 내 정보 확인 */
export const GET_MY_INFO = gql`
  query {
    getMyInfo {
      result {
        uuid
        userId
        name
        nickname
        email
        countryCode
        phone
        birth
        profileImage
        profileText
        friends
        rooms
        registDate
      }
      errCode
    }
  }
`;