import {
  useApolloClient,
  useReactiveVar,
  QueryOptions,
  MutationOptions,
  DefaultContext,
  ApolloCache
} from '@apollo/client';
import { useCallback } from 'react';
import userState from '@/gql/vars/user';

const useApollo = () => {
  const { token } = useReactiveVar(userState.getVar());

  const client = useApolloClient();

  const checkResult = useCallback(<T = any>(result: any) => {
    const { data } = result;
    if (!data) throw new Error('UNKOWN_ERROR');

    let isInvalidToken = false;
    Object.keys(data).forEach(key => {
      if (!!data[key].errCode && data[key].errCode === 'INVALID_TOKEN') {
        isInvalidToken = true;
      }
    });

    if (isInvalidToken) window.location.href = '/login';

    return data as T;
  }, []);

  const query = useCallback(async <R = any, V = any>(options: QueryOptions<V, R>, customToken: string | null | undefined = undefined) => {
    const authorization = !!token ? `Bearer ${token.accessToken}` : (!!customToken ? `Bearer ${customToken}` : undefined);

    const result = await client.query<R, V>({
      ...options,
      context: {
        ...options.context,
        headers: {
          authorization,
          ...options.context?.headers
        }
      }
    });

    return checkResult<R>(result);
  }, [client, token, checkResult]);

  const mutate = useCallback(async <R = any, V = any>(options: MutationOptions<R, V, DefaultContext, ApolloCache<any>>, customToken: string | null | undefined = undefined) => {
    const authorization = !!token ? `Bearer ${token.accessToken}` : (!!customToken ? `Bearer ${customToken}` : undefined);

    const result = await client.mutate<R, V>({
      ...options,
      context: {
        ...options.context,
        headers: {
          authorization,
          ...options.context?.headers
        }
      }
    });

    return checkResult<R>(result);
  }, [client, token, checkResult]);

  return { client, query, mutate };
};

export default useApollo;