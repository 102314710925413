import React, { useState } from 'react';
import { Route, Routes } from 'react-router';
import _ from 'lodash';
import AdminHeader from '@/pages/Admin/Header';
import AdminMain from '@/pages/Admin/Main';
import LoginAdmin from '@/pages/Admin/LoginAdmin';

export type NavigatorMenu = 'users' | 'banHistory' | 'noticeHistory';

interface AdminContextState {
  token: string | null
  navigatorOpen: boolean
  selectedMenu: NavigatorMenu
}

interface AdminContextValue {
  adminState: AdminContextState
  setAdminState: React.Dispatch<React.SetStateAction<AdminContextState>>
}

const adminContextInitialState: AdminContextState = {
  token: null,
  navigatorOpen: window.innerWidth >= 620,
  selectedMenu: 'users'
};

export const AdminContext = React.createContext<AdminContextValue>({
  adminState: adminContextInitialState,
  setAdminState: () => {}
});

const Admin = () => {
  const [adminState, setAdminState] = useState<AdminContextState>(_.cloneDeep(adminContextInitialState));

  return (
    <AdminContext.Provider value={{ adminState, setAdminState }} >
      <AdminHeader/>
      <Routes>
        <Route path='/' element={<AdminMain/>}/>
        <Route path='/login' element={<LoginAdmin/>}/>
      </Routes>
    </AdminContext.Provider>
  );
};

export default Admin;