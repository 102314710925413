import { useReactiveVar } from '@apollo/client';
import React, { HTMLProps, useMemo } from 'react';
import commonState from '@/gql/vars/common';

/** 각 페이지 기본 컨테이너 */
const PageContainer: React.FC<HTMLProps<HTMLDivElement>> = props => {
  const { theme } = useReactiveVar(commonState.getVar());

  const pageContainerClassName = useMemo(() => (
    theme === 'light' ? 'page-container' : 'page-container-dark'
  ), [theme]);

  return (
    <div
      {...props}
      className={
        props.className ?
          `${pageContainerClassName} ${props.className}` :
          pageContainerClassName
      }
    />
  );
};

export default PageContainer;