import { useCallback, useEffect, useState } from 'react';
import { Button, Link } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import Modal from '@/components/common/Modal';
import Text from '@/components/common/Text';
import commonState from '@/gql/vars/common';

interface OpenSourceInfo {
  name: string
  licenses: string
  repository: string
  publisher: string
}

interface SoftwareLicenseInfo {
  name: string
  license: string[]
}

const openSourceInfoFileNames = [
  'license-frontend.json',
  'license-backend.json',
  'software-licenses.json'
];

const Licenses = () => {
  const [chatLicense, setChatLicense] = useState<string[]>([]);
  const [licenses, setLicenses] = useState<OpenSourceInfo[]>([]);
  const [softwares, setSoftwares] = useState<SoftwareLicenseInfo[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalBodyHeight, setModalBodyHeight] = useState<number>(0);

  const { theme } = commonState.read();

  const handleModalOpen = useCallback(async () => {
    const { data: chatLicense } = await axios.get<string>('/LICENSE');

    const promises = openSourceInfoFileNames.map(d => axios.get(`/licenses/${d}`));
    const [licensesFrontend, licensesBackend, softwaresLicense] = (await Promise.all(promises)).map(d => d.data);

    const frontendLicenseArr = _.map(Object.keys(licensesFrontend), d => ({ name: d, ...licensesFrontend[d] }));
    const backendLicenseArr = _.map(Object.keys(licensesBackend), d => ({ name: d, ...licensesBackend[d] }));

    const licenses = _.uniqWith(_.concat(frontendLicenseArr, backendLicenseArr), _.isEqual);

    const softwares = softwaresLicense.map((d: any) => ({ ...d, license: d.license.split('\n') }));

    setChatLicense(chatLicense.split('\n'));
    setLicenses(licenses);
    setSoftwares(softwares);
    setModalOpen(true);
  }, []);

  useEffect(() => {
    let timeout: any = undefined;
    if (!modalOpen) timeout = setTimeout(() => {
      setLicenses([]);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [modalOpen]);

  useEffect(() => {
    setModalBodyHeight(window.innerHeight - 103);
    let timeout: any = undefined;

    const onResize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setModalBodyHeight(window.innerHeight - 103);
      }, 500);
    };

    window.addEventListener('resize', onResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <>
      <Button onClick={handleModalOpen}>LICENSES</Button>

      <Modal
        open={modalOpen}
        type='no-footer'
        title='Licenses'
        modalStyle={{ maxWidth: 'none', maxHeight: '100%', width: 'auto' }}
        onClose={() => setModalOpen(false)}
      >
        {chatLicense.length > 0 && licenses.length > 0 && softwares.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              height: modalBodyHeight,
              overflowX: 'hidden',
              overflowY: 'auto',
              padding: '15px'
            }}
          >
            <div>
              <Text variant='h4' style={{ lineBreak: 'anywhere' }}>Chat</Text>
              <br/>
              {chatLicense.map((d, i) => d === '' ? <br key={i}/> : <Text key={i}>{d}</Text>)}
            </div>

            <hr style={{ width: '100%' }}/>

            <Text variant='h5' style={{ lineBreak: 'anywhere' }}>Opensource</Text>
            {licenses.map((d, i) => (
              <div key={i}>
                <Text variant='h6' style={{ lineBreak: 'anywhere' }}>{d.name}</Text>
                <Text style={{ lineBreak: 'anywhere' }}>{d.publisher}</Text>
                <Link
                  href={d.repository}
                  color={theme === 'light' ? '#1976d2' : '#90caf9'}
                  style={{ lineBreak: 'anywhere', textUnderlinePosition: 'under' }}
                >
                  {d.repository}
                </Link>
                <Text style={{ lineBreak: 'anywhere' }}>{d.licenses}</Text>
              </div>
            ))}

            <hr style={{ width: '100%' }}/>

            <Text variant='h5' style={{ lineBreak: 'anywhere' }}>Softwares</Text>
            {softwares.map((d, i) => (
              <div key={i}>
                <Text variant='h6' style={{ lineBreak: 'anywhere' }}>{`${i + 1}. ${d.name}`}</Text>
                <br/>
                {d.license.map((d, i) => d === '' ? <br key={i}/> : <Text key={i}>{d}</Text>)}
              </div>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
};

export default Licenses;