import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, TextField } from '@mui/material';
import styled from 'styled-components';
import _ from 'lodash';
import Logo from '@/components/common/Logo';
import PageContainer from '@/components/common/PageContainer';
import Text from '@/components/common/Text';
import useTranslation from '@/hooks/useTranslation';
import commonConfig from '@/config/common.json';
import Contact from '@/pages/Login/Contact';
import Licenses from '@/pages/Login/Licenses';
import { ADMIN_LOGIN } from '@/gql/admin';
import Modal from '@/components/common/Modal';
import { AdminContext } from '@/pages/Admin';
import useApollo from '@/hooks/useApollo';

const Container = styled(PageContainer)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .login-wrapper {
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 280px;
    max-width: 100%;
    gap: 20px;
    margin: auto;

    .login-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      .logo {
        width: 60px;
        height: 60px;
      }
    }
  }
`;

/** 관리자 로그인 페이지 */
const LoginAdmin = () => {
  const [adminId, setAdminId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [errorModalMessageKey, setErrorModalMessageKey] = useState<string>('');
  const [inputInfo, setInputInfo] = useState({
    adminId: { error: false, helperText: '' },
    password: { error: false, helperText: '' }
  });

  const { adminState, setAdminState } = useContext(AdminContext);

  const t = useTranslation();
  const apollo = useApollo();
  const navigate = useNavigate();

  /** 아이디 변경 이벤트 */
  const handleChangeUserId: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(e => {
    if (inputInfo.adminId.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.adminId = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }

    setAdminId(e.target.value);
  }, [inputInfo]);

  /** 비밀번호 변경 이벤트 */
  const handleChangePassword: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(e => {
    if (inputInfo.password.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.password = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }

    setPassword(e.target.value);
  }, [inputInfo]);

  /** 로그인 버튼 클릭 이벤트 */
  const handleClickLoginButton: React.MouseEventHandler<HTMLButtonElement> = useCallback(async e => {
    e.preventDefault();

    const inputInfoClone = _.cloneDeep(inputInfo);
    let canLogin = true;

    if (!adminId) {
      inputInfoClone.adminId = { error: true, helperText: t('pleaseEnterUserId') };
      canLogin = false;
    }

    if (!password) {
      inputInfoClone.password = { error: true, helperText: t('pleaseEnterPassword') };
      canLogin = false;
    }

    if (!canLogin) {
      setInputInfo(inputInfoClone);
      return;
    }

    const data = await apollo.query<{ adminLogin: AdminLoginResult }, AdminLoginArgs>({
      query: ADMIN_LOGIN,
      variables: { adminId, password }
    });

    const { result, errCode } = data.adminLogin;

    if (errCode) {
      setErrorModalMessageKey(errCode === 'INVALID_ADMIN' ? 'wrongUserIdOrPassword' : 'serverErrorOccurrence');
      setErrorModalOpen(true);
      throw errCode;
    }

    setAdminState({ ...adminState, token: result });
    navigate('/admin');
  }, [adminId, password, inputInfo, adminState, apollo, setAdminState, t, navigate]);

  return (
    <>
      <Container>
        <form className='login-wrapper'>
          {/* 로그인 타이틀 */}
          <div className='login-title'>
            <Logo className='logo'/>
            <Text variant='h4' fontWeight='bold'>Chat {t('admin')}</Text>
          </div>

          {/* 아이디 */}
          <TextField
            label={t('userId')}
            variant='standard'
            value={adminId}
            error={inputInfo.adminId.error}
            helperText={inputInfo.adminId.helperText}
            onChange={handleChangeUserId}
          />

          {/* 비밀번호 */}
          <TextField
            label={t('password')}
            variant='standard'
            type='password'
            value={password}
            error={inputInfo.password.error}
            helperText={inputInfo.password.helperText}
            onChange={handleChangePassword}
          />

          {/* 로그인 버튼 */}
          <Button
            variant='outlined'
            type='submit'
            onClick={handleClickLoginButton}
            style={{ marginTop: '20px' }}
          >
            {t('login')}
          </Button>
        </form>

        {/* 에러 모달 */}
        <Modal
          open={errorModalOpen}
          type='error'
          title={t('errorOccurrence')}
          onClose={() => setErrorModalOpen(false)}
        >
          <div style={{ margin: '10px 0' }}>
            <Text>{t(`${errorModalMessageKey}`)}</Text>
          </div>
        </Modal>
      </Container>

      <PageContainer className='login-footer'>
        {/* 버전 정보 */}
        <div style={{ marginLeft: '10px' }}>
          <Text variant='caption'>v{commonConfig.version}</Text>
        </div>

        {/* 연락처, 라이선스 */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <Contact/>
          <Licenses/>
        </div>
      </PageContainer>
    </>
  );
};

export default LoginAdmin;