import { ReactiveVar } from '@apollo/client';

export interface ReactiveVarStateOptions {
  key: string
  persist: 'localStorage' | 'sessionStorage'
}

const storageKey = 'reactive-var-state';

class ReactiveVarState<T> {
  private _var: ReactiveVar<T>;
  private options?: ReactiveVarStateOptions;

  constructor(_var: ReactiveVar<T>, options?: ReactiveVarStateOptions) {
    this._var = _var;

    if (options) {
      this.options = options;

      const { key, persist } = options;
      const persistState = window[persist].getItem(storageKey);

      if (!!persistState) {
        const varData: T | undefined = JSON.parse(persistState)[key];

        if (varData) _var(varData);
      } else {
        window[persist].setItem(storageKey, JSON.stringify({}));
      }
    }
  }

  /** Reactive Var */
  getVar = () => this._var;

  /** Reactive Var 데이터 조회 */
  read = () => this._var();

  /** Reactive Var 데이터 수정 */
  set = (newState: T) => {
    const result = this._var(newState);
    
    if (!!this.options) {
      const { key, persist } = this.options;
      const persistState = window[persist].getItem(storageKey);

      if (!!persistState) {
        window[persist].setItem(storageKey, JSON.stringify({
          ...JSON.parse(persistState),
          [key]: result
        }));
      }
    }

    return result;
  };
}

export default ReactiveVarState;