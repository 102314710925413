import { useState } from 'react';
import { Button, Link } from '@mui/material';
import Modal from '@/components/common/Modal';
import Text from '@/components/common/Text';
import commonState from '@/gql/vars/common';

const Contact = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { theme } = commonState.read();

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>CONTACT</Button>

      <Modal
        open={modalOpen}
        type='no-footer'
        title='Contact'
        onClose={() => setModalOpen(false)}
      >
        <div style={{ display: 'flex', gap: '5px', margin: '10px' }}>
          <Text style={{ fontWeight: 'bold' }}>
            PHONE :
          </Text>
          <Text style={{ textUnderlinePosition: 'under' }}>
            <Link
              color={theme === 'light' ? '#1976d2' : '#90caf9'}
              href='tel:+821039219412'
            >
              +82 010-3921-9412
            </Link>
          </Text>
        </div>

        <div style={{ display: 'flex', gap: '5px', margin: '10px' }}>
          <Text style={{ fontWeight: 'bold' }}>
            E-MAIL :
          </Text>
          <Text style={{ textUnderlinePosition: 'under' }}>
            <Link
              color={theme === 'light' ? '#1976d2' : '#90caf9'}
              href='mailto:rlsh7321@gmail.com'
            >
              rlsh7321@gmail.com
            </Link>
          </Text>
        </div>
      </Modal>
    </>
  );
};

export default Contact;