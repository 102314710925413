import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import RefreshIcon from '@mui/icons-material/Refresh';
import BlockIcon from '@mui/icons-material/Block';
import Text from '@/components/common/Text';
import useTranslation from '@/hooks/useTranslation';
import commonState from '@/gql/vars/common';
import { Avatar, Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip } from '@mui/material';
import { BAN_USERS, GET_BAN_HISTORIES, GET_USER_DETAIL_INFO, SEND_NOTICE, UNBAN_USERS } from '@/gql/admin';
import Modal from '@/components/common/Modal';
import validate from '@/utils/validate';
import { AdminContext } from '@/pages/Admin';
import DatePicker from '@mui/lab/DatePicker';
import useApollo from '@/hooks/useApollo';

interface RowItem extends BanHistoriesData {
  id: number
  no: number
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  padding: 40px;
  z-index: 7;

  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .container-header-left {
      display: flex;
      align-items: center;
      gap: 20px;

      .icon {
        width: 40px;
        height: 40px;
        fill: ${({ theme }) => theme === 'light' ? '#3f3f3f' : '#ccc'};
      }
    }
  }

  .grid-wrapper {
    flex: 1;

    & * { scroll-behavior: unset; }
  }

  @media screen and (max-width: 620px) {
    position: absolute;
    width: 100%;
    left: 0;
    gap: 20px;
  }
`;

const ModalContainer = styled.div`
  .user-detail-info {
    width: 100%;

    p { min-width: 110px; }

    th {
      text-align: left;
      vertical-align: top;
      min-width: 100px;
      padding-left: 15px;

      p { font-weight: bold; }
    }

    td {
      padding-right: 15px;
      vertical-align: top;
    }

    td:first-child {
      text-align: center;
      vertical-align: middle;
      padding: 15px;
      padding-bottom: 25px;

      .profile-image {
        width: 150px;
        height: 150px;
        margin: auto;
      }
    }

    .connection-status {
      th, td {
        vertical-align: middle;
      }

      td > div {
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }
  }

  .user-detail-info-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding: 15px;
    padding-top: 25px;
  }

  .send-notice-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .notice-row-wrapper {
      display: flex;
      align-items: top;
      gap: 15px;
    }
  }
`;

const BanHistory = () => {
  const [rows, setRows] = useState<RowItem[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize] = useState<number>(30);
  const [gridWidth, setGridWidth] = useState<number>(0);
  const [userDetailInfo, setUserDetailInfo] = useState<UserDetailInfo | null>(null);
  const [banModalOpen, setBanModalOpen] = useState<boolean>(false);
  const [banDay, setBanDay] = useState<number>(7);
  const [unbanModalOpen, setUnbanModalOpen] = useState<boolean>(false);
  const [sendNoticeModalOpen, setSendNoticeModalOpen] = useState<boolean>(false);
  const [noticeDate, setNoticeDate] = useState<{ start: string, end: string }>({ start: '', end: '' });
  const [noticeTitle, setNoticeTitle] = useState<{ ko: string, en: string }>({ ko: '', en: '' });
  const [noticeContents, setNoticeContents] = useState<{ ko: string, en: string }>({ ko: '', en: '' });
  const [sendImmediately, setSendImmediately] = useState<boolean>(false);
  const [forceSignout, setForceSignout] = useState<boolean>(false);
  const [inputInfo, setInputInfo] = useState({
    banDay: { error: false, helperText: '' },
    noticeStartDate: { error: false, helperText: '' },
    noticeEndDate: { error: false, helperText: '' },
    noticeTitle: { error: false, helperText: '' },
    noticeTitleEn: { error: false, helperText: '' },
    noticeContents: { error: false, helperText: '' },
    noticeContentsEn: { error: false, helperText: '' }
  });

  const { token } = useContext(AdminContext).adminState;

  const { theme } = commonState.read();

  const t = useTranslation();
  const apollo = useApollo();

  /** 유저 목록 조회 요청 */
  const getRows = useCallback(async (newPage: number, force: boolean = false) => {
    if (!force && newPage <= page) {
      setRows(rows.slice(0, pageSize * (newPage + 1)));
      setPage(newPage);
      return;
    }

    try {
      const data = await apollo.query<{ getBanHistories: GetBanHistoriesResult }, GetBanHistoriesArgs>({
        query: GET_BAN_HISTORIES,
        variables: { page: newPage, pageSize }
      }, token);

      const { result, errCode } = data.getBanHistories;
      if (errCode) throw errCode;

      const {
        rowCount: newRowCount,
        page: currentPage,
        data: banHistoriesData
      } = result;

      const lastRow = _.findLast(rows);

      const newRows: RowItem[] = banHistoriesData.map((d, i) => ({
        id: i + (force || lastRow === undefined ? 0 : lastRow.id + 1),
        no: i + (force || lastRow === undefined ? 1 : lastRow.no + 1),
        ...d,
        banDate: moment(d.banDate).format('YYYY-MM-DD HH:mm'),
        unbanDate: moment(d.unbanDate).format('YYYY-MM-DD HH:mm')
      }));

      if (rowCount !== newRowCount) setRowCount(newRowCount);
      setRows([...(force ? [] : rows), ...newRows]);
      setPage(currentPage);
    } catch (err) {
      console.error(err);
    }
  }, [token, rows, rowCount, page, pageSize, apollo]);

  /** 유저 상세 정보 조회 */
  const handleDoubleClickRow = useCallback(async (row: RowItem) => {
    const { userUuid } = row;

    try {
      const data = await apollo.query<{ getUserDetailInfo: GetUserDetailInfoResult }, GetUserDetailInfoArgs>({
        query: GET_USER_DETAIL_INFO,
        variables: { userUuid }
      }, token);

      const { result, errCode } = data.getUserDetailInfo;
      if (errCode) throw errCode;

      console.log(result);
      setUserDetailInfo(result);
    } catch (err) {
      console.error(err);
    }
  }, [token, apollo]);

  /** 정지 버튼 클릭 이벤트 */
  const handleClickBanButton = useCallback(() => {
    const inputInfoClone = _.cloneDeep(inputInfo);
    inputInfoClone.banDay = { error: false, helperText: '' };
    setBanDay(7);
    setBanModalOpen(true);
    setInputInfo(inputInfoClone);
  }, [inputInfo]);

  /** 정지 기간 변경 이벤트 */
  const handleChangeBanDay = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (inputInfo.banDay.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.banDay = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }

    if (e.target.value === '') return setBanDay(0);
    if (!validate('numberstring', e.target.value)) return;

    setBanDay(+e.target.value);
  }, [inputInfo]);

  /** 유저 정지 요청 */
  const banUserRequest = useCallback(async (userUuid: string) => {
    try {
      const data = await apollo.mutate<{ banUsers: BanUsersResult }, BanUsersArgs>({
        mutation: BAN_USERS,
        variables: {
          userUuid: [userUuid],
          banDay
        }
      }, token);

      const { errCode } = data.banUsers;
      if (errCode) throw errCode;

      getRows(0, true);
    } catch (err) {
      console.error(err);
    }
  }, [token, apollo, banDay, getRows]);

  /** 유저 정지 해제 요청 */
  const unbanUserRequest = useCallback(async (userUuid: string) => {
    try {
      const data = await apollo.mutate<{ unbanUsers: UnbanUsersResult }, UnbanUsersArgs>({
        mutation: UNBAN_USERS,
        variables: { userUuid: [userUuid] }
      }, token);

      const { errCode } = data.unbanUsers;
      if (errCode) throw errCode;

      getRows(0, true);
    } catch (err) {
      console.error(err);
    }
  }, [token, apollo, getRows]);

  /** 사용자 계정 정지 모달 확인 버튼 클릭 이벤트 */
  const handleClickBanUserAccountsModalConfirmButton = useCallback(async () => {
    if (!userDetailInfo) return;
    const inputInfoClone = _.cloneDeep(inputInfo);

    if (!banDay) {
      inputInfoClone.banDay = { error: true, helperText: 'pleaseEnterCorrectBanDay' };
      return setInputInfo(inputInfoClone);
    }

    await banUserRequest(userDetailInfo.uuid);
    setBanModalOpen(false);
    setUserDetailInfo(null);
  }, [banDay, userDetailInfo, inputInfo, banUserRequest]);

  /** 사용자 계정 정지 해제 모달 확인 버튼 클릭 이벤트 */
  const handleClickUnbanUserAccountsModalConfirmButton = useCallback(async () => {
    if (!userDetailInfo) return;
    await unbanUserRequest(userDetailInfo.uuid);
    setUnbanModalOpen(false);
    setUserDetailInfo(null);
  }, [userDetailInfo, unbanUserRequest]);

  /** 공지 전송 버튼 클릭 이벤트 */
  const handleClickSendNoticeButton = useCallback(() => {
    const now = (new Date()).toISOString();
    setNoticeDate({ start: now, end: now });
    setNoticeTitle({ ko: '', en: '' });
    setNoticeContents({ ko: '', en: '' });
    setSendNoticeModalOpen(true);
    setInputInfo({
      ...inputInfo,
      noticeStartDate: { error: false, helperText: '' },
      noticeEndDate: { error: false, helperText: '' },
      noticeTitle: { error: false, helperText: '' },
      noticeTitleEn: { error: false, helperText: '' },
      noticeContents: { error: false, helperText: '' },
      noticeContentsEn: { error: false, helperText: '' }
    });
  }, [inputInfo]);

  /** 공지 시작 날짜 변경 이벤트 */
  const handleChangeNoticeStartDate = useCallback((date: Date | null) => {
    if (inputInfo.noticeStartDate.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.noticeStartDate = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }
    setNoticeDate({ ...noticeDate, start: date?.toISOString() || (new Date()).toISOString() });
  }, [noticeDate, inputInfo]);

  /** 공지 종료 날짜 변경 이벤트 */
  const handleChangeNoticeEndDate = useCallback((date: Date | null) => {
    if (inputInfo.noticeEndDate.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.noticeEndDate = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }
    setNoticeDate({ ...noticeDate, end: date?.toISOString() || (new Date()).toISOString() });
  }, [noticeDate, inputInfo]);

  /** 공지 제목(한국어) 변경 이벤트 */
  const handleChangeNoticeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (inputInfo.noticeTitle.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.noticeTitle = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }
    setNoticeTitle({ ...noticeTitle, ko: e.target.value });
  }, [noticeTitle, inputInfo]);

  /** 공지 제목(영어) 변경 이벤트 */
  const handleChangeNoticeTitleEn = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (inputInfo.noticeTitleEn.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.noticeTitleEn = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }
    setNoticeTitle({ ...noticeTitle, en: e.target.value });
  }, [noticeTitle, inputInfo]);

  /** 공지 내용(한국어) 변경 이벤트 */
  const handleChangeNoticeContents = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (inputInfo.noticeContents.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.noticeContents = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }
    setNoticeContents({ ...noticeContents, ko: e.target.value });
  }, [noticeContents, inputInfo]);

  /** 공지 내용(영어) 변경 이벤트 */
  const handleChangeNoticeContentsEn = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (inputInfo.noticeContentsEn.error) {
      const inputInfoClone = _.cloneDeep(inputInfo);
      inputInfoClone.noticeContentsEn = { error: false, helperText: '' };
      setInputInfo(inputInfoClone);
    }
    setNoticeContents({ ...noticeContents, en: e.target.value });
  }, [noticeContents, inputInfo]);

  /** 공지 전송 모달 확인 버튼 클릭 이벤트 */
  const handleClickSendNoticeModalConfirmButton = useCallback(async (userUuid?: string) => {
    if (!userUuid) return;
    const inputInfoClone = _.cloneDeep(inputInfo);

    if (noticeDate.end <= noticeDate.start) {
      inputInfoClone.noticeEndDate = { error: true, helperText: 'pleaseEnterCorrectNoticeEndDate' };
    }

    if (!noticeTitle.ko) {
      inputInfoClone.noticeTitle = { error: true, helperText: 'pleaseEnterNoticeTitle' };
    }

    if (!noticeTitle.en) {
      inputInfoClone.noticeTitleEn = { error: true, helperText: 'pleaseEnterNoticeTitle' };
    }

    if (!noticeContents.ko) {
      inputInfoClone.noticeContents = { error: true, helperText: 'pleaseEnterNoticeContents' };
    }

    if (!noticeContents.en) {
      inputInfoClone.noticeContentsEn = { error: true, helperText: 'pleaseEnterNoticeContents' };
    }

    if (!_.isEqual(inputInfo, inputInfoClone)) {
      setInputInfo(inputInfoClone);
      return;
    }

    try {
      const data = await apollo.mutate<{ sendNotice: SendNoticeResult }, SendNoticeArgs>({
        mutation: SEND_NOTICE,
        variables: {
          userUuid: [userUuid],
          noticeDate,
          noticeTitle,
          noticeContents,
          sendImmediately,
          forceSignout
        }
      }, token);

      const { errCode } = data.sendNotice;
      if (errCode) throw errCode;

      setSendNoticeModalOpen(false);
    } catch (err) {
      console.error(err);
    }
  }, [token, noticeDate, noticeTitle, noticeContents, sendImmediately, forceSignout, inputInfo, apollo]);

  /** 컬럼 */
  const columns: GridColDef[] = useMemo(() => (gridWidth > 800 ? [
    { field: 'no', headerName: 'No', flex: 1 },
    { field: 'userId', headerName: t('userId'), flex: 3 },
    { field: 'nickname', headerName: t('nickname'), flex: 3 },
    { field: 'banDate', headerName: t('banDate'), flex: 3 },
    { field: 'unbanDate', headerName: t('unbanDate'), flex: 3 },
    { field: 'manualUnban', headerName: t('manualUnban'), flex: 3 }
  ] : [
    { field: 'no', headerName: 'No', width: 80, flex: 0 },
    { field: 'userId', headerName: t('userId'), width: 172, flex: 0 },
    { field: 'nickname', headerName: t('nickname'), width: 172, flex: 0 },
    { field: 'banDate', headerName: t('banDate'), width: 172, flex: 0 },
    { field: 'unbanDate', headerName: t('unbanDate'), width: 172, flex: 0 },
    { field: 'manualUnban', headerName: t('manualUnban'), width: 172, flex: 0 }
  ]), [gridWidth, t]);

  useEffect(() => {
    getRows(0, true);
  }, []);

  return (
    <>
      <Container theme={theme}>
        {/* 헤더 */}
        <div className='container-header'>
          <div className='container-header-left'>
            <BlockIcon className='icon'/>
            <Text variant='h4'>{t('banHistory')}</Text>
          </div>

          <div className='container-header-right'>
            <Tooltip title={t('refreshList')}>
              <IconButton onClick={() => getRows(0, true)}>
                <RefreshIcon/>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* 유저 목록 테이블 */}
        <div className='grid-wrapper'>
          <DataGrid
            className='data-table'
            rows={rows}
            columns={columns}
            onResize={params => setGridWidth(params.width)}
            onRowDoubleClick={params => handleDoubleClickRow(params.row)}
            onCellClick={params => window.innerWidth < 620 && params.field === 'no' && handleDoubleClickRow(params.row)}
            rowsPerPageOptions={[pageSize]}
            rowCount={rowCount}
            page={page}
            pageSize={pageSize}
            onPageChange={page => getRows(page)}
            componentsProps={{
              header: { className: 'data-table-header' },
              row: { className: 'data-table-row' },
              footer: { className: 'data-table-footer' }
            }}
          />
        </div>
      </Container>

      <ModalContainer>
        {/* 사용자 상세 정보 모달 */}
        <Modal
          open={!!userDetailInfo}
          type='no-footer'
          title={t('userDetailInfo')}
          modalStyle={{ width: '400px' }}
          onClose={() => setUserDetailInfo(null)}
        >
          {!!userDetailInfo && (
            <>
              <table className='user-detail-info'>
                <thead>
                  <tr>
                    <td colSpan={2}>
                      <Avatar className='profile-image' src={userDetailInfo.profileImage}/>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <Text>{t('userId')}</Text>
                    </th>
                    <td>
                      <Text>{userDetailInfo.userId}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('nickname')}</Text>
                    </th>
                    <td>
                      <Text>{userDetailInfo.nickname}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('name')}</Text>
                    </th>
                    <td>
                      <Text>{userDetailInfo.name}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('phone')}</Text>
                    </th>
                    <td>
                      <Text>{userDetailInfo.phone}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('email')}</Text>
                    </th>
                    <td>
                      <Text>{userDetailInfo.email}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('birth')}</Text>
                    </th>
                    <td>
                      <Text>{moment(userDetailInfo.birth).format('YYYY-MM-DD')}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('profileText')}</Text>
                    </th>
                    <td>
                      <Text>{userDetailInfo.profileText}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('registDate')}</Text>
                    </th>
                    <td>
                      <Text>{moment(userDetailInfo.registDate).format('YYYY-MM-DD HH:mm:ss')}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('accountStatus')}</Text>
                    </th>
                    <td>
                      <Text>{userDetailInfo.isBan ? t('ban') : t('active')}</Text>
                    </td>
                  </tr>
                  <tr className='connection-status'>
                    <th>
                      <Text>{t('connectionStatus')}</Text>
                    </th>
                    <td>
                      <div
                        style={{
                          backgroundColor: userDetailInfo.connectionStatus ?
                            (theme === 'light' ? '#81c784' : '#388e3c') :
                            (theme === 'light' ? '#e57373' : '#d32f2f')
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* 사용자 상세 정보 모달 하단 */}
              <div className='user-detail-info-footer'>
                {userDetailInfo.isBan ? (
                  <Button
                    variant='contained'
                    size='small'
                    color='warning'
                    onClick={() => setUnbanModalOpen(true)}
                  >
                    {t('unban')}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant='contained'
                      size='small'
                      color='error'
                      onClick={handleClickBanButton}
                    >
                      {t('ban')}
                    </Button>
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      onClick={handleClickSendNoticeButton}
                    >
                      {t('sendNotice')}
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </Modal>

        {/* 사용자 계정 정지 모달 */}
        <Modal
          open={banModalOpen}
          type='confirm'
          title={t('ban')}
          onClose={() => setBanModalOpen(false)}
          onConfirm={handleClickBanUserAccountsModalConfirmButton}
        >
          <div style={{ margin: '10px 0' }}>
            <Text>{t(!!userDetailInfo ? 'banSelectedUser' : 'banSelectedUsers')}</Text>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                marginTop: '10px'
              }}
            >
              <Text>{t('banDay')} :</Text>
              <TextField
                size='small'
                value={banDay + ''}
                error={inputInfo.banDay.error}
                onChange={handleChangeBanDay}
                style={{ width: '60px' }}
                inputProps={{ style: { textAlign: 'center' } }}
              />
              <Text>{t('days')}</Text>
            </div>
            <Text variant='caption' color={theme === 'light' ? '#d32f2f' : '#f44336'}>
              {t(inputInfo.banDay.helperText)}
            </Text>
          </div>
        </Modal>

        {/* 사용자 계정 정지 해제 모달 */}
        <Modal
          open={unbanModalOpen}
          type='confirm'
          title={t('unban')}
          onClose={() => setUnbanModalOpen(false)}
          onConfirm={handleClickUnbanUserAccountsModalConfirmButton}
        >
          <div style={{ margin: '10px 0' }}>
            <Text>{t(!!userDetailInfo ? 'unbanSelectedUser' : 'unbanSelectedUsers')}</Text>
          </div>
        </Modal>

        {/* 공지 전송 모달 */}
        <Modal
          open={sendNoticeModalOpen}
          type='confirm'
          title={t('sendNotice')}
          modalStyle={{ minWidth: 'unset', width: 'auto' }}
          onClose={() => setSendNoticeModalOpen(false)}
          onConfirm={() => handleClickSendNoticeModalConfirmButton(userDetailInfo?.uuid)}
        >
          <div className='send-notice-modal-body'>
            <Text>{t(!!userDetailInfo ? 'sendNoticeToSelectedUser' : 'sendNoticeToSelectedUsers')}</Text>

            <div className='notice-row-wrapper'>
              {/* 공지 시작 날짜 */}
              <DatePicker
                label={t('noticeStartDate')}
                inputFormat='YYYY-MM-DD HH:mm'
                mask='____-__-__ __:__'
                value={new Date(noticeDate.start)}
                PaperProps={{ className: theme === 'light' ? 'scroll-bar' : 'scroll-bar-dark' }}
                onChange={handleChangeNoticeStartDate}
                renderInput={params => (
                  <TextField
                    {...params}
                    size='small'
                    error={inputInfo.noticeStartDate.error}
                    helperText={t(inputInfo.noticeStartDate.helperText)}
                    required
                  />
                )}
              />
              <Text style={{ marginTop: '5px' }}>~</Text>
              {/* 공지 종료 날짜 */}
              <DatePicker
                label={t('noticeEndDate')}
                inputFormat='YYYY-MM-DD HH:mm'
                mask='____-__-__ __:__'
                value={new Date(noticeDate.end)}
                PaperProps={{ className: theme === 'light' ? 'scroll-bar' : 'scroll-bar-dark' }}
                onChange={handleChangeNoticeEndDate}
                renderInput={params => (
                  <TextField
                    {...params}
                    size='small'
                    error={inputInfo.noticeEndDate.error}
                    helperText={t(inputInfo.noticeEndDate.helperText)}
                    required
                  />
                )}
              />
            </div>

            <div className='notice-row-wrapper'>
              {/* 공지 제목 (한국어) */}
              <TextField
                size={'small'}
                label={t('noticeTitleKo')}
                value={noticeTitle.ko}
                error={inputInfo.noticeTitle.error}
                helperText={t(inputInfo.noticeTitle.helperText)}
                required
                onChange={handleChangeNoticeTitle}
                style={{ flex: 1 }}
              />

              {/* 공지 제목 (영어) */}
              <TextField
                size={'small'}
                label={t('noticeTitleEn')}
                value={noticeTitle.en}
                error={inputInfo.noticeTitleEn.error}
                helperText={t(inputInfo.noticeTitleEn.helperText)}
                required
                onChange={handleChangeNoticeTitleEn}
                style={{ flex: 1 }}
              />
            </div>

            <div className='notice-row-wrapper'>
              {/* 공지 내용 (한국어) */}
              <TextField
                size={'small'}
                label={t('noticeContentsKo')}
                multiline
                rows={10}
                maxRows={10}
                value={noticeContents.ko}
                error={inputInfo.noticeContents.error}
                helperText={t(inputInfo.noticeContents.helperText)}
                required
                onChange={handleChangeNoticeContents}
                style={{ flex: 1 }}
              />

              {/* 공지 내용 (영어) */}
              <TextField
                size={'small'}
                label={t('noticeContentsEn')}
                multiline
                rows={10}
                maxRows={10}
                value={noticeContents.en}
                error={inputInfo.noticeContentsEn.error}
                helperText={t(inputInfo.noticeContentsEn.helperText)}
                required
                onChange={handleChangeNoticeContentsEn}
                style={{ flex: 1 }}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* 즉시 전송 */}
              <div>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={sendImmediately}
                      onChange={e => {
                        setSendImmediately(e.target.checked);
                        setForceSignout(false);
                      }}
                    />
                  )}
                  label={<Text>{t('sendImmediately')}</Text>}
                />
              </div>

              {/* 공지 확인 시 강제 로그아웃 */}
              {sendImmediately && (
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={forceSignout} onChange={e => setForceSignout(e.target.checked)}/>}
                    label={<Text>{t('forceSignOutAfterConfirmNotice')}</Text>}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal>
      </ModalContainer>
    </>
  );
};

export default BanHistory;