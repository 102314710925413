import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import useTranslation from '@/hooks/useTranslation';
import Text from '@/components/common/Text';
import Backdrop from '@/components/common/Backdrop';
import { useReactiveVar } from '@apollo/client';
import commonState from '@/gql/vars/common';

interface AlertModalProps {
  open: boolean
  type: 'alert'
  title: string | JSX.Element
  closeButton?: boolean
  confirmText?: string
  closeText?: string
  onConfirm: () => void
  onClose?: () => void
  center?: boolean
  children?: React.ReactNode
  modalStyle?: React.CSSProperties
}

interface ConfirmModalProps {
  open: boolean
  type: 'confirm'
  title: string | JSX.Element
  closeButton?: boolean
  confirmText?: string
  closeText?: string
  onConfirm: () => void
  onClose: () => void
  center?: boolean
  children?: React.ReactNode
  modalStyle?: React.CSSProperties
}

interface ErrorModalProps {
  open: boolean
  type: 'error'
  title: string | JSX.Element
  closeButton?: boolean
  confirmText?: string
  closeText?: string
  onConfirm?: () => void
  onClose: () => void
  center?: boolean
  children?: React.ReactNode
  modalStyle?: React.CSSProperties
}

interface NoFooterModalProps {
  open: boolean
  type: 'no-footer'
  title: string | JSX.Element
  closeButton?: boolean
  confirmText?: string
  closeText?: string
  onConfirm?: () => void
  onClose: () => void
  center?: boolean
  children?: React.ReactNode
  modalStyle?: React.CSSProperties
}

type ModalProps =
  | AlertModalProps
  | ConfirmModalProps
  | ErrorModalProps
  | NoFooterModalProps;

const Container = styled.div`
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 20;

  .modal-container {
    width: 400px;
    max-width: 100%;
    z-index: 10;
    background-color: #fff;
    border-radius: 5px;
    margin: auto;
    overflow: hidden;
    transition: opacity 0.2s ease-in-out;
    background-color: ${({ theme }) => theme === 'light' ? '#fff' : '#555'};
    z-index: 20;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 53px;
      padding: 10px;
      border-bottom: 1px solid ${({ theme }) => theme === 'light' ? '#ddd' : '#777'};

      .modal-title-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .close-btn {
        fill: ${({ theme }) => theme === 'light' ? '#000' : '#fff'};
        cursor: pointer;
      }
    }

    .modal-body {
      padding: 10px;
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-top: 1px solid ${({ theme }) => theme === 'light' ? '#ddd' : '#777'};
    }
  }
`;

/** Modal Component */
const Modal: React.FC<ModalProps> = props => {
  const {
    open,
    type,
    title,
    closeButton,
    confirmText,
    closeText,
    onClose,
    onConfirm,
    center,
    modalStyle,
    children
  } = props;

  const [display, setDisplay] = useState<'flex' | 'none'>('none');
  const [opacity, setOpacity] = useState<number>(0);

  const { theme } = useReactiveVar(commonState.getVar());

  const t = useTranslation();

  useEffect(() => {
    let timeout: number = 0;

    if (open) {
      setDisplay('flex');
      timeout = window.setTimeout(() => setOpacity(1), 10);
    } else {
      setOpacity(0);
      timeout = window.setTimeout(() => setDisplay('none'), 200);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <Container theme={theme} style={{ display }}>
      <Backdrop style={{ opacity }}/>

      <div className='modal-container' style={{ ...modalStyle, opacity }}>
        {/* 모달 Header */}
        <div className='modal-header'>
          <div className='modal-title-container'>
            {type === 'alert' && (
              <CampaignRoundedIcon style={{ fill: theme === 'light' ? '#000' : '#fff' }}/>
            )}
            {type === 'confirm' && (
              <CheckCircleRoundedIcon style={{ fill: theme === 'light' ? '#0c9131' : '#fff' }}/>
            )}
            {type === 'error' && (
              <ErrorRoundedIcon style={{ fill: theme === 'light' ? '#db3939' : '#ff6969' }}/>
            )}
            {typeof title === 'string' && <Text variant='h6'>{title}</Text>}
            {typeof title !== 'string' && title}
          </div>
          {closeButton && <CloseRounded className='close-btn' onClick={onClose}/>}
        </div>

        {/* 모달 Body */}
        <div
          className='modal-body'
          style={center ? {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          } : undefined}
        >
          {children}
        </div>

        {/* 모달 Footer */}
        {type !== 'no-footer' && (
          <div className='modal-footer'>
            {['confirm', 'error'].indexOf(type) > -1 && (
              <Button
                variant='contained'
                onClick={onClose}
                style={{ backgroundColor: '#999' }}
              >
                {closeText || t('close')}
              </Button>
            )}

            {['alert', 'confirm'].indexOf(type) > -1 && (
              <Button
                variant='contained'
                onClick={onConfirm}
              >
                {confirmText || t('confirm')}
              </Button>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

Modal.defaultProps = {
  closeButton: true
};

export default Modal;