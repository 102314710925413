import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import commonState from '@/gql/vars/common';

interface TextProps extends TypographyProps {
  color?: string
}

const Text: React.FC<TextProps> = props => {
  const { color } = props;

  const { theme } = useReactiveVar(commonState.getVar());

  return (
    <Typography
      {...props}
      style={{
        ...props.style,
        color: color || (theme === 'light' ? '#000' : '#fff')
      }}
    />
  );
};

export default Text;