import { useCallback, useContext, useEffect, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import styled from 'styled-components';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';
import BlockIcon from '@mui/icons-material/Block';
import CampaignIcon from '@mui/icons-material/Campaign';
import { AdminContext, NavigatorMenu } from '@/pages/Admin';
import Text from '@/components/common/Text';
import commonState from '@/gql/vars/common';
import useTranslation from '@/hooks/useTranslation';
import Backdrop from '@/components/common/Backdrop';

interface AdminNavigatorProps {
  open: boolean
}

const Container = styled.div<AdminNavigatorProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ open }) => open ? '300px' : '0'};
  max-width: 100%;
  height: 100%;
  left: ${({ open }) => open ? '0' : '-300px'};
  border-right: 1px solid ${({ theme }) => theme === 'light' ? '#bbb' : '#272727'};
  background-color: ${({ theme }) => theme === 'light' ? '#fff' : '#2b2b2b'};
  transition: ${({ open }) => open ? 'left 0.4s ease-out, width 0.3s ease-in' : 'left 0.3s ease-in, width 0.4s ease-out'};
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9;

  .navigator-wrapper {
    width: 300px;

    .admin-navigator-title {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 15px;
  
      .icon {
        width: 40px;
        height: 40px;
        fill: ${({ theme }) => theme === 'light' ? '#3f3f3f' : '#fff'}
      }
    }
  }
`;

const AdminNavigator = () => {
  const [containerOverflow, setContainerOverflow] = useState<'hidden' | undefined>('hidden');
  const [backdropDisplay, setBackdropDisplay] = useState<'none' | undefined>('none');
  const [backdropOpacity, setBackdropOpacity] = useState<number>(0);

  const { theme } = commonState.read();

  const { adminState, setAdminState } = useContext(AdminContext);
  const { navigatorOpen, selectedMenu } = adminState;

  const t = useTranslation();

  /** 네비게이터 메뉴 변경 */
  const handleSelectMenu = useCallback((menu: NavigatorMenu) => {
    if (adminState.selectedMenu === menu) return;
    setAdminState({ ...adminState, navigatorOpen: document.body.clientWidth > 620, selectedMenu: menu });
  }, [adminState, setAdminState]);

  /** drawerOpen 변경 시 */
  useEffect(() => {
    if (navigatorOpen) {
      setBackdropDisplay(undefined);
      setTimeout(() => setContainerOverflow(undefined), 300);
      setTimeout(() => setBackdropOpacity(1), 10);
    } else {
      setBackdropOpacity(0);
      setContainerOverflow('hidden');
      setTimeout(() => setBackdropDisplay('none'), 200);
    }
  }, [navigatorOpen]);

  return (
    <>
      <Container theme={theme} open={navigatorOpen} style={{ overflow: containerOverflow }}>
        <div className='navigator-wrapper'>
          <div className='admin-navigator-title'>
            <AdminPanelSettingsIcon className='icon'/>

            <Text variant='h5'>
              Chat {t('admin')}
            </Text>
          </div>

          <hr style={{ margin: '0 15px' }}/>

          <List>
            {/* 사용자 메뉴 */}
            <ListItem>
              <ListItemButton selected={selectedMenu === 'users'} onClick={() => handleSelectMenu('users')}>
                <ListItemIcon>
                  <PeopleIcon/>
                </ListItemIcon>
                <Text>{t('users')}</Text>
              </ListItemButton>
            </ListItem>

            {/* 정지 내역 메뉴 */}
            <ListItem>
              <ListItemButton selected={selectedMenu === 'banHistory'} onClick={() => handleSelectMenu('banHistory')}>
                <ListItemIcon>
                  <BlockIcon/>
                </ListItemIcon>
                <Text>{t('banHistory')}</Text>
              </ListItemButton>
            </ListItem>

            {/* 공지 내역 메뉴 */}
            <ListItem>
              <ListItemButton selected={selectedMenu === 'noticeHistory'} onClick={() => handleSelectMenu('noticeHistory')}>
                <ListItemIcon>
                  <CampaignIcon/>
                </ListItemIcon>
                <Text>{t('noticeHistory')}</Text>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Container>

      {document.body.clientWidth <= 620 && (
        <Backdrop
          onClick={() => setAdminState({ ...adminState, navigatorOpen: false })}
          style={{
            display: backdropDisplay,
            opacity: backdropOpacity,
            zIndex: 8
          }}
        />
      )}
    </>
  );
};

export default AdminNavigator;