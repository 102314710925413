import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ko from '@/config/lang/ko.json';
import en from '@/config/lang/en.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ko: { translation: ko },
      en: { translation: en }
    },
    lng: 'ko',
    interpolation: { escapeValue: false }
  });