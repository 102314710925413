import { gql } from '@apollo/client';

export const ADMIN_LOGIN = gql`
  query ($adminId: String!, $password: String!) {
    adminLogin(adminId: $adminId, password: $password) {
      result
      errCode
    }
  }
`;

/** 전체 유저 목록 조회 */
export const GET_ALL_USERS_INFO = gql`
  query {
    getAllUsersInfo {
      result {
        userUuid
        userId
        nickname
        name
        phone
        email
        isBan
      }
      errCode
    }
  }
`;

/** 유저 상세 정보 조회 */
export const GET_USER_DETAIL_INFO = gql`
  query ($userUuid: String!) {
    getUserDetailInfo(userUuid: $userUuid) {
      result {
        uuid
        userId
        nickname
        name
        phone
        email
        birth
        profileImage
        profileText
        registDate
        isBan
        connectionStatus
      }
      errCode
    }
  }
`;

/** 유저 정지 */
export const BAN_USERS = gql`
  mutation ($userUuid: [String]!, $banDay: Int!) {
    banUsers (
      userUuid: $userUuid
      banDay: $banDay
    ) {
      result
      errCode
    }
  }
`;

/** 유저 정지 해제 */
export const UNBAN_USERS = gql`
  mutation ($userUuid: [String]!) {
    unbanUsers (
      userUuid: $userUuid
    ) {
      result
      errCode
    }
  }
`;

/** 정지 내역 조회 */
export const GET_BAN_HISTORIES = gql`
  query ($page: Int!, $pageSize: Int!) {
    getBanHistories(page: $page, pageSize: $pageSize) {
      result {
        rowCount
        page
        pageSize
        data {
          uuid
          userUuid
          userId
          nickname
          name
          phone
          email
          isBan
          banDate
          unbanDate
          manualUnban
          updateDate
        }
      }
      errCode
    }
  }
`;

/** 공지 전송 */
export const SEND_NOTICE = gql`
  mutation (
    $userUuid: [String]!
    $noticeDate: NoticeDate!
    $noticeTitle: NoticeText!
    $noticeContents: NoticeText!
    $sendImmediately: Boolean!
    $forceSignout: Boolean!
  ) {
    sendNotice (
      userUuid: $userUuid
      noticeDate: $noticeDate
      noticeTitle: $noticeTitle
      noticeContents: $noticeContents
      sendImmediately: $sendImmediately
      forceSignout: $forceSignout
    ) {
      result
      errCode
    }
  }
`;

/** 공지 내역 조회 */
export const GET_NOTICE_HISTORIES = gql`
  query ($page: Int!, $pageSize: Int!) {
    getNoticeHistories (
      page: $page
      pageSize: $pageSize
    ) {
      result {
        rowCount
        page
        pageSize
        data {
          uuid
          userUuid
          userId
          nickname
          noticeTitle
          noticeTitleEn
          registDate
        }
      }
      errCode
    }
  }
`;

/** 공지 내역 상세 조회 */
export const GET_NOTICE_DETAIL_INFO = gql`
  query ($userUuid: String!, $noticeUuid: String!) {
    getUserDetailInfo(userUuid: $userUuid) {
      result {
        uuid
        userId
        nickname
        name
        profileImage
      }
      errCode
    }
    getNoticeDetailInfo(noticeUuid: $noticeUuid) {
      result {
        uuid
        noticeTitle
        noticeTitleEn
        noticeContents
        noticeContentsEn
        noticeStartDate
        noticeEndDate
        registDate
        read
      }
    }
  }
`;