import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation as useTranslationDefault } from 'react-i18next';
import commonState from '@/gql/vars/common';

const useTranslation = () => {
  const { lang } = useReactiveVar(commonState.getVar());

  const { t: translate } = useTranslationDefault();

  const t = useCallback((key: string) => translate(key, { lng: lang }), [lang, translate]);

  return t;
};

export default useTranslation;