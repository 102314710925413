import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LanguageIcon from '@mui/icons-material/Language';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import * as uuid from 'uuid';
import Logo from '@/components/common/Logo';
import Text from '@/components/common/Text';
import useTranslation from '@/hooks/useTranslation';
import { ReactComponent as KoreanFlag } from '@/assets/svg/korea.svg';
import { ReactComponent as AmericanFlag } from '@/assets/svg/america.svg';
import { ReactComponent as GitlabIcon } from '@/assets/svg/gitlab.svg';
import Modal from '@/components/common/Modal';
import drawerState, { setDrawerOpen } from '@/gql/vars/drawer';
import commonState, { setLang, setTheme } from '@/gql/vars/common';
import userState from '@/gql/vars/user';
import { setAutoLoginRefreshToken } from '@/gql/vars/autoLogin';

interface StyledContainerProps {
  theme: 'light' | 'dark'
  isLogin: boolean
}

const Container = styled.div<StyledContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  padding: 5px 10px;
  border-bottom: ${({ theme }) => theme === 'light' ? '1px solid #bbb' : '1px solid #252525'};
  background-color: ${({ theme }) => theme === 'light' ? '#fff' : '#252525'};

  .header-left {
    display: flex;
    align-items: center;
    gap: 10px;

    .menu-icon {
      fill: ${({ theme }) => theme === 'light' ? '#3f3f3f' : '#ccc'};
    }

    .logo {
      width: 55px;
      height: 55px;
      ${({ isLogin }) => isLogin ? '' : 'margin-left: 10px;'}
      cursor: pointer;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    gap: 5px;

    .icon {
      width: 40px;
      height: 40px;
      padding: 5px;
      fill: ${({ theme }) => theme === 'light' ? '#3f3f3f' : '#ccc'};
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  gap: 10px;

  .flag-icon {
    width: 1em;
  }
`;

const Header = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [signOutModalOpen, setSignOutModalOpen] = useState<boolean>(false);

  const { theme, lang } = useReactiveVar(commonState.getVar());
  const { userInfo, token } = useReactiveVar(userState.getVar());
  const { drawerOpen } = useReactiveVar(drawerState.getVar());

  const t = useTranslation();
  const navigate = useNavigate();

  /** 헤더 좌측 메뉴 버튼 클릭 이벤트 */
  const handleClickLeftMenuButton = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  /** 헤더 우측 테마, 언어 메뉴 버튼 클릭 이벤트 */
  const handleClickRightMenuButton: React.MouseEventHandler<HTMLButtonElement> = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  /** 헤더 좌측 로고 클릭 이벤트 */
  const handleClickLogo = useCallback(() => {
    navigate('/');
  }, [navigate]);

  /** 테마 변경 */
  const handleClickThemeMenuItemButton = useCallback((theme: ThemeMode) => {
    setTheme(theme);
  }, []);

  /** 언어 변경 */
  const handleClickLanguageMenuItemButton = useCallback((language: Language) => {
    setLang(language);
  }, []);

  /** 로그아웃 버튼 클릭 */
  const handleClickLogoutButton = useCallback(() => {
    setSignOutModalOpen(true);
  }, []);

  /** 로그아웃 모달 확인 버튼 클릭 */
  const handleClickLogoutConfirmButton = useCallback(() => {
    setAutoLoginRefreshToken();
    setSignOutModalOpen(false);
    navigate('/login');
  }, [navigate]);

  /** 로그아웃 모달 닫기 버튼 클릭 */
  const handleClickLogoutCloseButton = useCallback(() => {
    setSignOutModalOpen(false);
  }, []);

  return (
    <Container theme={theme} isLogin={!!userInfo && !!token}>
      {/* 헤더 좌측 */}
      <div className='header-left'>
        {/* 메뉴 버튼 */}
        {!!userInfo && !!token && (
          <IconButton onClick={handleClickLeftMenuButton}>
            <MenuRoundedIcon className='menu-icon'/>
          </IconButton>
        )}

        {/* 로고 */}
        <Logo className='logo' onClick={handleClickLogo}/>
      </div>

      {/* 헤더 우측 */}
      <div className='header-right'>
        {/* Gitlab 링크 버튼 */}
        {(!userInfo || !token) && (
          <Tooltip title='Git'>
            <IconButton id='theme-btn' style={{ marginRight: '3px' }} onClick={() => window.location.href = 'https://gitlab.com/chat25'}>
              <GitlabIcon className='icon' style={{ padding: '7px' }}/>
            </IconButton>
          </Tooltip>
        )}

        {/* 테마 변경 버튼 */}
        <Tooltip title={t('theme')}>
          <IconButton id='theme-btn' onClick={handleClickRightMenuButton}>
            {theme === 'light' && <LightModeIcon className='icon'/>}
            {theme === 'dark' && <DarkModeIcon className='icon'/>}
          </IconButton>
        </Tooltip>

        {/* 언어 변경 버튼 */}
        <Tooltip title={t('language')}>
          <IconButton id='lang-btn' onClick={handleClickRightMenuButton}>
            <LanguageIcon className='icon'/>
          </IconButton>
        </Tooltip>

        {/* 로그아웃 버튼 */}
        {!!userInfo && !!token && (
          <Tooltip title={t('signOut')}>
            <IconButton id='signout-btn' onClick={handleClickLogoutButton}>
              <MeetingRoomRoundedIcon className='icon'/>
            </IconButton>
          </Tooltip>
        )}

        {/* 테마, 언어 변경 버튼 클릭 메뉴 */}
        {anchorEl && (
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            {/* 테마 메뉴 */}
            {anchorEl.id === 'theme-btn' && ([
              <StyledMenuItem key={uuid.v4()} className='menu-item' onClick={() => handleClickThemeMenuItemButton('light')}>
                <LightModeIcon/>
                <Text variant='body1'>{t('lightMode')}</Text>
              </StyledMenuItem>,
              <StyledMenuItem key={uuid.v4()} className='menu-item' onClick={() => handleClickThemeMenuItemButton('dark')}>
                <DarkModeIcon/>
                <Text variant='body1'>{t('darkMode')}</Text>
              </StyledMenuItem>,
              <Divider key={uuid.v4()}/>,
              <StyledMenuItem key={uuid.v4()} disabled>
                <Text variant='body1'>{t('current')}: </Text>
                <Text variant='body1' fontWeight='bold'>{theme === 'light' ? t('lightMode') : t('darkMode')}</Text>
              </StyledMenuItem>
            ])}

            {/* 언어 메뉴 */}
            {anchorEl.id === 'lang-btn' && ([
              <StyledMenuItem key={uuid.v4()} className='menu-item' onClick={() => handleClickLanguageMenuItemButton('ko')}>
                <KoreanFlag className='flag-icon'/>
                <Text variant='body1'>한국어</Text>
              </StyledMenuItem>,
              <StyledMenuItem key={uuid.v4()} className='menu-item' onClick={() => handleClickLanguageMenuItemButton('en')}>
                <AmericanFlag className='flag-icon'/>
                <Text variant='body1'>English</Text>
              </StyledMenuItem>,
              <Divider key={uuid.v4()}/>,
              <StyledMenuItem key={uuid.v4()} disabled>
                <Text variant='body1'>{t('current')}: </Text>
                <Text variant='body1' fontWeight='bold'>{lang === 'ko' ? '한국어' : 'English'}</Text>
              </StyledMenuItem>
            ])}
          </Menu>
        )}

        {/* 로그아웃 확인 모달 */}
        {!!userInfo && (
          <Modal
            open={signOutModalOpen}
            type='confirm'
            title={t('signOut')}
            onConfirm={handleClickLogoutConfirmButton}
            onClose={handleClickLogoutCloseButton}
          >
            <div style={{ margin: '10px 0' }}>
              <Text>{t('signOutConfirm')}</Text>
            </div>
          </Modal>
        )}
      </div>
    </Container>
  );
};

export default Header;