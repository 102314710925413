import { useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import loadable from '@loadable/component';
import moment from 'moment';
import Header from '@/components/common/Header';
import userState, { closeBanModal, refreshToken } from '@/gql/vars/user';
import commonState from '@/gql/vars/common';
import PageContainer from '@/components/common/PageContainer';
import { refreshFriendsList } from '@/gql/vars/drawer';
import Admin from '@/pages/Admin';
import Modal from '@/components/common/Modal';
import useTranslation from '@/hooks/useTranslation';
import Text from '@/components/common/Text';

const Fallback = styled(PageContainer)`
  width: 100%;
  height: 100%;
`;

const Login = loadable(() => import(/* webpackPrefetch: true */ '@/pages/Login'), { fallback: <Fallback/> });
const FindAccount = loadable(() => import(/* webpackPrefetch: true */ '@/pages/FindAccount'), { fallback: <Fallback/> });
const SignUp = loadable(() => import(/* webpackPrefetch: true */ '@/pages/SignUp'), { fallback: <Fallback/> });
const Main = loadable(() => import(/* webpackPrefetch: true */ '@/pages/Main'), { fallback: <Fallback/> });

const Router = () => {
  const { theme } = useReactiveVar(commonState.getVar());
  const { token, banModal } = useReactiveVar(userState.getVar());

  const themeMode = useMemo(() => createTheme({ palette: { mode: theme } }), [theme]);

  const t = useTranslation();

  /** 로그인 상태일 때 10분마다 Access Token, 친구 목록, 채팅방 목록 갱신 요청 */
  useEffect(() => {
    if (!token) return;

    const timeout = setTimeout(async () => {
      refreshToken();
      refreshFriendsList();
      refreshFriendsList();
    }, 10 * 60 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [token]);

  /** body 배경색상 변경 */
  useEffect(() => {
    document.body.style.backgroundColor = theme === 'light' ? '#fff' : '#353535';
  }, [theme]);

  return (
    <ThemeProvider theme={themeMode}>
      <BrowserRouter>
        {window.location.pathname.indexOf('/admin') < 0 && <Header/>}
        <Routes>
          <Route path='/login' element={<Login/>}/>
          <Route path='/find-account' element={<FindAccount/>}/>
          <Route path='/sign-up' element={<SignUp/>}/>
          <Route path='/admin/*' element={<Admin/>}/>
          <Route path='/*' element={<Main/>}/>
        </Routes>
      </BrowserRouter>

      {/* 계정 정지 모달 */}
      <Modal
        open={banModal.open}
        type='alert'
        title={t('bannedAccount')}
        onClose={closeBanModal}
        onConfirm={closeBanModal}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', margin: '10px 0' }}>
          <Text>{t('bannedAccountMessage')}</Text>
          <Text>{t('banDay')} : {moment(banModal.banDate).format('YYYY-MM-DD HH:mm')} ~ {moment(banModal.unbanDate).format('YYYY-MM-DD HH:mm')}</Text>
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default Router;