import { gql } from '@apollo/client';

/** 친구/친구목록 조회 */
export const GET_FRIENDS_INFO = gql`
  query ($lastNickname: String) {
    getFriendsInfo(
      lastNickname: $lastNickname
    ) {
      result {
        friendRequests {
          uuid
          nickname
          profileImage
          profileText
        }
        friends {
          uuid
          nickname
          profileImage
          profileText
        }
      }
      errCode
    }
  }
`;

/** 닉네임으로 유저 검색 */
export const SEARCH_USER_BY_NICKNAME = gql`
  query ($nickname: String!, $lastNickname: String) {
    searchUserByNickname(
      nickname: $nickname
      lastNickname: $lastNickname
    ) {
      result {
        uuid
        nickname
        profileImage
        profileText
      }
      errCode
    }
  }
`;

/** 친구 요청 */
export const FRIEND_REQUEST = gql`
  mutation ($requestUserUuid: String!) {
    friendRequest(
      requestUserUuid: $requestUserUuid
    ) {
      result
      errCode
    }
  }
`;

/** 친구 수락/거절 */
export const FRIEND_RESPONSE = gql`
  mutation ($requestUserUuid: String!, $isAccept: Boolean!) {
    friendResponse(
      requestUserUuid: $requestUserUuid
      isAccept: $isAccept
    ) {
      result
      errCode
    }
  }
`;

/** 친구 삭제 */
export const DELETE_FRIEND = gql`
  mutation ($friendUuid: String!) {
    deleteFriend(
      friendUuid: $friendUuid
    ) {
      result
      errCode
    }
  }
`;

/** 친구 목록 검색 */
export const SEARCH_FRIENDS_INFO = gql`
  query ($searchNickname: String, $lastNickname: String) {
    getFriendsInfo(
      searchNickname: $searchNickname
      lastNickname: $lastNickname
    ) {
      result {
        friends {
          uuid
          nickname
          profileImage
        }
      }
      errCode
    }
  }
`;