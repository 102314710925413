type ValidateType =
  | 'email'
  | 'numberstring'
  | 'userid'
  | 'password'
  | 'nickname';

const regex = {
  /** 이메일 정규식 */
  email: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{1,}$/i,
  /** 숫자 문자열 정규식 */
  numberstring: /^[0-9]+$/,
  /** 영어/숫자 포함 6~12자 아이디 정규식 */
  userid: /^[a-zA-Z0-9]{6,12}$/,
  /** 영문/숫자 최소 1자, 8~16자 비밀번호 정규식 */
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,16}$/,
  /** 한글/영어/숫자 포함 2~12자 닉네임 정규식 */
  nickname: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]{2,12}$/
};

const validate = (type: ValidateType, value: string) => regex[type].test(value);

export default validate;