import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { AdminContext } from '@/pages/Admin';
import PageContainer from '@/components/common/PageContainer';
import AdminNavigator from '@/pages/Admin/Navigator';
import Users from '@/pages/Admin/Users';
import BanHistory from '@/pages/Admin/BanHistory';
import NoticeHistory from '@/pages/Admin/NoticeHistory';

const Container = styled(PageContainer)`
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: auto;
`;

const AdminMain = () => {
  const { adminState } = useContext(AdminContext);
  const { selectedMenu } = adminState;

  const navigate = useNavigate();

  useEffect(() => {
    if (/* process.env.NODE_ENV !== 'development' && */ !adminState.token) {
      navigate('/admin/login');
      return;
    }
  }, [adminState, navigate]);

  if (!adminState.token) return null;

  return (
    <Container>
      <AdminNavigator/>
      {selectedMenu === 'users' && <Users/>}
      {selectedMenu === 'banHistory' && <BanHistory/>}
      {selectedMenu === 'noticeHistory' && <NoticeHistory/>}
    </Container>
  );
};

export default AdminMain;