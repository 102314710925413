import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import RefreshIcon from '@mui/icons-material/Refresh';
import CampaignIcon from '@mui/icons-material/Campaign';
import Text from '@/components/common/Text';
import useTranslation from '@/hooks/useTranslation';
import commonState from '@/gql/vars/common';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import { GET_NOTICE_DETAIL_INFO, GET_NOTICE_HISTORIES } from '@/gql/admin';
import Modal from '@/components/common/Modal';
import { AdminContext } from '@/pages/Admin';
import useApollo from '@/hooks/useApollo';

interface RowItem extends NoticeHistoriesData {
  id: number
  no: number
}

interface NoticeDetailInfo {
  uuid: string
  userUuid: string
  userId: string
  nickname: string
  name: string
  profileImage: string
  noticeTitle: string
  noticeContents: string
  noticeStartDate: string
  noticeEndDate: string
  registDate: string
  read: boolean
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  padding: 40px;
  z-index: 7;

  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .container-header-left {
      display: flex;
      align-items: center;
      gap: 20px;

      .icon {
        width: 40px;
        height: 40px;
        fill: ${({ theme }) => theme === 'light' ? '#3f3f3f' : '#ccc'};
      }
    }
  }

  .grid-wrapper {
    flex: 1;

    & * { scroll-behavior: unset; }
  }

  @media screen and (max-width: 620px) {
    position: absolute;
    width: 100%;
    left: 0;
    gap: 20px;
  }
`;

const ModalContainer = styled.div`
  .user-detail-info {
    width: 100%;

    p { min-width: 110px; }

    th {
      text-align: left;
      vertical-align: top;
      min-width: 100px;
      padding-left: 15px;

      p { font-weight: bold; }
    }

    td {
      padding-right: 15px;
      vertical-align: top;
    }

    td:first-child {
      text-align: center;
      vertical-align: middle;
      padding: 15px;
      padding-bottom: 25px;

      .profile-image {
        width: 150px;
        height: 150px;
        margin: auto;
      }
    }
  }
`;

const NoticeHistory = () => {
  const [rows, setRows] = useState<RowItem[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize] = useState<number>(30);
  const [gridWidth, setGridWidth] = useState<number>(0);
  const [noticeDetailInfo, setNoticeDetailInfo] = useState<NoticeDetailInfo | null>(null);

  const { token } = useContext(AdminContext).adminState;

  const { theme, lang } = commonState.read();

  const t = useTranslation();
  const apollo = useApollo();

  /** 유저 목록 조회 요청 */
  const getRows = useCallback(async (newPage: number, force: boolean = false) => {
    if (!force && newPage <= page) {
      setRows(rows.slice(0, pageSize * (newPage + 1)));
      setPage(newPage);
      return;
    }

    try {
      const data = await apollo.query<{ getNoticeHistories: GetNoticeHistoriesResult }, GetNoticeHistoriesArgs>({
        query: GET_NOTICE_HISTORIES,
        variables: { page: newPage, pageSize }
      }, token);

      const { result, errCode } = data.getNoticeHistories;
      if (errCode) throw errCode;

      const {
        rowCount: newRowCount,
        page: currentPage,
        data: noticeHistoriesData
      } = result;

      const lastRow = _.findLast(rows);

      const newRows: RowItem[] = noticeHistoriesData.map((d, i) => ({
        id: i + (force || lastRow === undefined ? 0 : lastRow.id + 1),
        no: i + (force || lastRow === undefined ? 1 : lastRow.no + 1),
        ...d,
        registDate: moment(d.registDate).format('YYYY-MM-DD HH:mm')
      }));

      if (rowCount !== newRowCount) setRowCount(newRowCount);
      setRows([...(force ? [] : rows), ...newRows]);
      setPage(currentPage);
    } catch (err) {
      console.error(err);
    }
  }, [token, rows, rowCount, page, pageSize, apollo]);

  /** 유저 상세 정보 조회 */
  const handleDoubleClickRow = useCallback(async (row: RowItem) => {
    const { userUuid, uuid: noticeUuid } = row;

    try {
      const data = await apollo.query<{ getUserDetailInfo: GetUserDetailInfoResult, getNoticeDetailInfo: GetNoticeDetailInfoResult }, { userUuid: string, noticeUuid: string }>({
        query: GET_NOTICE_DETAIL_INFO,
        variables: { userUuid, noticeUuid }
      }, token);

      const { result: userInfo, errCode: errCode1 } = data.getUserDetailInfo;
      const { result: noticeInfo, errCode: errCode2 } = data.getNoticeDetailInfo;
      if (errCode1) throw errCode1;
      if (errCode2) throw errCode2;

      console.log({ userInfo, noticeInfo });
      setNoticeDetailInfo({
        uuid: noticeInfo.uuid,
        userUuid: userInfo.uuid,
        userId: userInfo.userId,
        nickname: userInfo.nickname,
        name: userInfo.name,
        profileImage: userInfo.profileImage,
        noticeTitle: lang === 'ko' ? noticeInfo.noticeTitle : noticeInfo.noticeTitleEn,
        noticeContents: lang === 'ko' ? noticeInfo.noticeContents : noticeInfo.noticeContentsEn,
        noticeStartDate: moment(noticeInfo.noticeStartDate).format('YYYY-MM-DD HH:mm'),
        noticeEndDate: moment(noticeInfo.noticeEndDate).format('YYYY-MM-DD HH:mm'),
        registDate: moment(noticeInfo.registDate).format('YYYY-MM-DD HH:mm'),
        read: noticeInfo.read
      });
    } catch (err) {
      console.error(err);
    }
  }, [token, lang, apollo]);

  /** 컬럼 */
  const columns: GridColDef[] = useMemo(() => (gridWidth > 800 ? [
    { field: 'no', headerName: 'No', flex: 1 },
    { field: 'userId', headerName: t('userId'), flex: 3 },
    { field: 'nickname', headerName: t('nickname'), flex: 3 },
    { field: lang === 'ko' ? 'noticeTitle' : 'noticeTitleEn', headerName: t('noticeTitle'), flex: 3 },
    { field: 'registDate', headerName: t('registDate2'), flex: 3 }
  ] : [
    { field: 'no', headerName: 'No', width: 80, flex: 0 },
    { field: 'userId', headerName: t('userId'), width: 172, flex: 0 },
    { field: 'nickname', headerName: t('nickname'), width: 172, flex: 0 },
    { field: lang === 'ko' ? 'noticeTitle' : 'noticeTitleEn', headerName: t('noticeTitle'), width: 172, flex: 0 },
    { field: 'registDate', headerName: t('registDate2'), width: 172, flex: 0 }
  ]), [lang, gridWidth, t]);

  useEffect(() => {
    getRows(0, true);
  }, []);

  return (
    <>
      <Container theme={theme}>
        {/* 헤더 */}
        <div className='container-header'>
          <div className='container-header-left'>
            <CampaignIcon className='icon'/>
            <Text variant='h4'>{t('noticeHistory')}</Text>
          </div>

          <div className='container-header-right'>
            <Tooltip title={t('refreshList')}>
              <IconButton onClick={() => getRows(0, true)}>
                <RefreshIcon/>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* 유저 목록 테이블 */}
        <div className='grid-wrapper'>
          <DataGrid
            className='data-table'
            rows={rows}
            columns={columns}
            onResize={params => setGridWidth(params.width)}
            onRowDoubleClick={params => handleDoubleClickRow(params.row)}
            onCellClick={params => window.innerWidth < 620 && params.field === 'no' && handleDoubleClickRow(params.row)}
            rowsPerPageOptions={[pageSize]}
            rowCount={rowCount}
            page={page}
            pageSize={pageSize}
            onPageChange={page => getRows(page)}
            componentsProps={{
              header: { className: 'data-table-header' },
              row: { className: 'data-table-row' },
              footer: { className: 'data-table-footer' }
            }}
          />
        </div>
      </Container>

      <ModalContainer>
        {/* 공지 상세 정보 모달 */}
        <Modal
          open={!!noticeDetailInfo}
          type='alert'
          title={t('noticeDetailInfo')}
          modalStyle={{ width: '400px' }}
          onClose={() => setNoticeDetailInfo(null)}
          onConfirm={() => setNoticeDetailInfo(null)}
        >
          {!!noticeDetailInfo && (
            <>
              <table className='user-detail-info'>
                <thead>
                  <tr>
                    <td colSpan={2}>
                      <Avatar className='profile-image' src={noticeDetailInfo.profileImage}/>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <Text>{t('userId')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.userId}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('nickname')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.nickname}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('name')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.name}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('registDate2')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.registDate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('noticeStartDate')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.noticeStartDate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('noticeEndDate')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.noticeEndDate}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('noticeTitle')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.noticeTitle}</Text>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Text>{t('noticeContents')}</Text>
                    </th>
                    <td>
                      <Text>{noticeDetailInfo.noticeContents}</Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </Modal>
      </ModalContainer>
    </>
  );
};

export default NoticeHistory;