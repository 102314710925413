import React from 'react';
import { makeVar } from '@apollo/client';
import * as uuid from 'uuid';
import { LOGIN, REFRESH_ACCESS_TOKEN } from '@/gql/user';
import { refreshFriendsList, refreshRoomList, setDrawerOpen } from '@/gql/vars/drawer';
import ReactiveVarState from '@/utils/ReactiveVarState';
import apollo from '@/utils/apolloClient';
import { setAutoLoginRefreshToken } from '@/gql/vars/autoLogin';

export interface UserVar {
  userInfo?: UserInfo
  token?: TokenInfo
  banModal: {
    open: boolean
    banDate: string
    unbanDate: string
  }
}

export interface LoginParams {
  userId: string
  password: string
  autoLogin: boolean
  setErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setErrorModalMessageKey: React.Dispatch<React.SetStateAction<string>>
}

let refreshUuid: string = uuid.v4();

const initialState: UserVar = {
  userInfo: undefined,
  token: undefined,
  banModal: {
    open: false,
    banDate: '',
    unbanDate: ''
  }
};

export const userVar = makeVar<UserVar>(initialState);

const userState = new ReactiveVarState(userVar, {
  key: 'user',
  persist: 'sessionStorage'
});

export const setUserInfo = (userInfo?: UserInfo) => userState.set({
  ...userVar(),
  userInfo
});

export const setToken = (token?: TokenInfo) => userState.set({
  ...userVar(),
  token
});

export const openBanModal = (banDate: string, unbanDate: string) => userState.set({
  ...userVar(),
  banModal: {
    open: true,
    banDate,
    unbanDate
  }
});

export const closeBanModal = () => {
  userState.set({
    ...userVar(),
    banModal: {
      open: false,
      banDate: '',
      unbanDate: ''
    }
  });
  window.location.href = '/login';
};

/** 로그인 Request */
export const login = async (params: LoginParams) => {
  const { userId, password, autoLogin, setErrorModalOpen, setErrorModalMessageKey } = params;

  try {
    const { data } = await apollo.query<{ login: LoginResult }, LoginArgs>({
      query: LOGIN,
      variables: { userId, password },
      fetchPolicy: 'no-cache'
    });

    const { result, errCode } = data.login;

    if (errCode) {
      if (errCode === 'WRONG_LOGIN_INFO') {
        setErrorModalMessageKey('wrongUserIdOrPassword');
        setErrorModalOpen(true);
      }

      if (errCode === 'SERVER_ERROR') {
        setErrorModalMessageKey('serverErrorOccurrence');
        setErrorModalOpen(true);
      }

      if (!!result) {
        const { banDate, unbanDate } = result;

        if (errCode === 'BANNED_USER' && !!banDate && !!unbanDate) {
          openBanModal(banDate, unbanDate);
        }

        throw errCode;
      }
    }

    const { userInfo, token } = result;

    if (document.body.clientWidth > 620) setDrawerOpen(true);
    else setDrawerOpen(false);

    setUserInfo(userInfo);
    setToken(token);
    refreshFriendsList(userInfo, token);
    refreshRoomList(token);
    setAutoLoginRefreshToken(autoLogin && token ? token.refreshToken : undefined);

    refreshUuid = uuid.v4();
  } catch (err) {
    console.error(err);
  }
};

/** Access Token 갱신 */
export const refreshToken = async () => {
  const { token } = userVar();
  if (!token) return;

  try {
    const { data } = await apollo.query<{ refreshAccessToken: RefreshAccessTokenResult }, RefreshAccessTokenArgs>({
      query: REFRESH_ACCESS_TOKEN,
      variables: { uuid: refreshUuid },
      context: {
        headers: { authorization: `Bearer ${token.refreshToken}` }
      }
    });

    const { result, errCode } = data.refreshAccessToken;

    if (errCode) {
      if (errCode === 'INVALID_TOKEN') return window.location.href = '/login';
      throw errCode;
    }

    setToken({ ...token, accessToken: result });
  } catch (err) {
    console.error(err);
  }
};

export default userState;