import { makeVar } from '@apollo/client';
import { GET_FRIENDS_INFO } from '@/gql/friends';
import { GET_OPEN_CHAT_ROOM_LIST, GET_ROOM_LIST } from '@/gql/room';
import apollo from '@/utils/apolloClient';
import ReactiveVarState from '@/utils/ReactiveVarState';
import { userVar, setUserInfo } from '@/gql/vars/user';

type AuthType = 'EMAIL' | 'PHONE';

interface DrawerVar {
  drawerOpen: boolean
  settingsAuthMethod?: AuthType
  settingsAuthUuid?: string
  friendsRequest: Friend[]
  friendsList: Friend[]
  roomList: RoomListItem[]
  noReadNum: { [key: string]: number }

  openChatRoomName: string
  originOpenChatRoomName: string
  searchOpenChatRoomList: RoomListItem[] | null
  searchOpenChatRoomPage: number
}

const initialState: DrawerVar = {
  drawerOpen: document.body.clientWidth > 620,
  settingsAuthMethod: undefined,
  settingsAuthUuid: undefined,
  friendsRequest: [],
  friendsList: [],
  roomList: [],
  noReadNum: {},
  openChatRoomName: '',
  originOpenChatRoomName: '',
  searchOpenChatRoomList: null,
  searchOpenChatRoomPage: 1
};

const drawerVar = makeVar<DrawerVar>(initialState);

const drawerState = new ReactiveVarState(drawerVar);

export const setDrawerOpen = (drawerOpen: boolean) => drawerState.set({
  ...drawerVar(),
  drawerOpen
});

export const setSettingsAuthInfo = (settingsAuthMethod?: AuthType, settingsAuthUuid?: string) => drawerState.set({
  ...drawerVar(),
  settingsAuthMethod,
  settingsAuthUuid
});

export const setFriendsRequest = (friendsRequest: Friend[]) => drawerState.set({
  ...drawerVar(),
  friendsRequest
});

export const setFriendsList = (friendsList: Friend[]) => drawerState.set({
  ...drawerVar(),
  friendsList
});

export const setRoomList = (roomList: RoomListItem[]) => drawerState.set({
  ...drawerVar(),
  roomList
});

export const setNoReadNum = (roomUuid: string, noReadNum: number) => drawerState.set({
  ...drawerVar(),
  noReadNum: {
    ...drawerVar().noReadNum,
    [roomUuid]: noReadNum
  }
});

export const setOpenChatRoomName = (openChatRoomName: string) => drawerState.set({
  ...drawerVar(),
  openChatRoomName
});

export const setOriginOpenChatRoomName = (originOpenChatRoomName: string) => drawerState.set({
  ...drawerVar(),
  originOpenChatRoomName
});

export const setSearchOpenChatRoomList = (searchOpenChatRoomList?: RoomListItem[]) => drawerState.set({
  ...drawerVar(),
  searchOpenChatRoomList: searchOpenChatRoomList || null
});

export const setSearchOpenChatRoomPage = (searchOpenChatRoomPage: number) => drawerState.set({
  ...drawerVar(),
  searchOpenChatRoomPage
});

export const resetSearchOpenChatRoomInfo = () => {
  setOpenChatRoomName('');
  setOriginOpenChatRoomName('');
  setSearchOpenChatRoomList();
  setSearchOpenChatRoomPage(1);
};

/** 친구 목록 갱신 */
export const refreshFriendsList = async (userInfo?: UserInfo, token?: TokenInfo) => {
  const userState = userVar();
  if (!userInfo) userInfo = userState.userInfo;
  if (!token) token = userState.token;
  if (!userInfo || !token) return;

  try {
    const { data } = await apollo.query<{ getFriendsInfo: GetFriendsInfoResult }, GetFriendsInfoArgs>({
      query: GET_FRIENDS_INFO,
      variables: { lastNickname: '' },
      context: {
        headers: { authorization: `Bearer ${token?.accessToken}` }
      }
    });

    const { result, errCode } = data.getFriendsInfo;

    if (errCode) {
      if (errCode === 'INVALID_TOKEN') return window.location.href = '/login';
      throw errCode;
    }

    setUserInfo({ ...userInfo, friends: result.friends.map(d => d.uuid) });
    setFriendsRequest(result.friendRequests);
    setFriendsList(result.friends);
  } catch (err) {
    console.error(err);
    return;
  }
};

/** 채팅방 목록 갱신 */
export const refreshRoomList = async (token?: TokenInfo) => {
  const userState = userVar();
  if (!token) token = userState.token;
  if (!token) return;

  try {
    const { data } = await apollo.query<{ getRoomList: GetRoomListResult }>({
      query: GET_ROOM_LIST,
      context: {
        headers: { authorization: `Bearer ${token?.accessToken}` }
      }
    });

    const { result, errCode } = data.getRoomList;

    if (errCode) {
      if (errCode === 'INVALID_TOKEN') return window.location.href = '/login';
      throw errCode;
    }

    console.log(data.getRoomList);
    setRoomList(result.roomList);
    drawerState.set({
      ...drawerVar(),
      noReadNum: JSON.parse(result.noReadNumJsonString)
    });
  } catch (err) {
    console.error(err);
  }
};

/** 오픈 채팅방 검색 목록 조회 */
export const getOpenChatRoomList = async (roomName: string, page: number = 1) => {
  const { token } = userVar();
  const { searchOpenChatRoomList } = drawerVar();

  if (page > 1 && !searchOpenChatRoomList) {
    setSearchOpenChatRoomList();
    setSearchOpenChatRoomPage(1);
    return;
  }

  try {
    const { data } = await apollo.query<{ getOpenChatRoomList: GetOpenChatRoomResult }, GetOpenChatRoomArgs>({
      query: GET_OPEN_CHAT_ROOM_LIST,
      variables: { roomName, page },
      context: {
        headers: { authorization: `Bearer ${token?.accessToken}` }
      }
    });

    const { result, errCode } = data.getOpenChatRoomList;

    if (errCode) {
      if (errCode === 'INVALID_TOKEN') return window.location.href = '/login';
      throw errCode;
    }

    console.log(data);
    setSearchOpenChatRoomList(page === 1 ? result : searchOpenChatRoomList?.concat(result));
    setSearchOpenChatRoomPage(page);
  } catch (err) {
    console.error(err);
  }
};

export default drawerState;