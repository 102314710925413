import { useReactiveVar } from '@apollo/client';
import React, { SVGProps } from 'react';
import { ReactComponent as LogoIcon } from '@/assets/svg/logo.svg';
import commonState from '@/gql/vars/common';

const Logo: React.FC<SVGProps<SVGSVGElement>> = props => {
  const { theme } = useReactiveVar(commonState.getVar());

  return (
    <LogoIcon fill={theme === 'light' ? '#3f3f3f' : '#ccc'} {...props}/>
  );
};

export default Logo;