import { makeVar } from '@apollo/client';
import * as uuid from 'uuid';
import apollo from '@/utils/apolloClient';
import ReactiveVarState from '@/utils/ReactiveVarState';
import { GET_MY_INFO, REFRESH_ACCESS_TOKEN } from '@/gql/user';
import { setToken, setUserInfo } from '@/gql/vars/user';

interface AutoLoginVar {
  refreshToken?: string
}

const initialState: AutoLoginVar = {
  refreshToken: undefined
};

export const autoLoginVar = makeVar<AutoLoginVar>(initialState);

const autoLoginState = new ReactiveVarState(autoLoginVar, {
  key: 'autoLogin',
  persist: 'localStorage'
});

export const setAutoLoginRefreshToken = (refreshToken?: string) => autoLoginState.set({ refreshToken });

/** 자동 로그인 확인 */
export const checkAutoLogin = async () => {
  const { refreshToken } = autoLoginVar();
  if (!refreshToken) return;

  try {
    const { data: data1 } = await apollo.query<{ refreshAccessToken: RefreshAccessTokenResult }, RefreshAccessTokenArgs>({
      query: REFRESH_ACCESS_TOKEN,
      variables: { uuid: uuid.v4() },
      context: {
        headers: { authorization: `Bearer ${refreshToken}` }
      }
    });

    const { result: accessToken, errCode: errCode1 } = data1.refreshAccessToken;
    if (errCode1) throw errCode1;

    const { data: data2 } = await apollo.query<{ getMyInfo: GetMyInfoResult }>({
      query: GET_MY_INFO,
      context: {
        headers: { authorization: `Bearer ${accessToken}` }
      }
    });

    const { result: userInfo, errCode: errCode2 } = data2.getMyInfo;
    if (errCode1) throw errCode2;

    setUserInfo(userInfo);
    setToken({ accessToken, refreshToken });
  } catch (err) {
    setAutoLoginRefreshToken();
    console.error(err);
  }
};

export default autoLoginState;