import { gql } from '@apollo/client';

/** 개인채팅방 정보 조회 */
export const GET_PERSONAL_ROOM_INFO = gql`
  query ($userUuid: String!) {
    getPersonalRoomInfo(
      userUuid: $userUuid
    ) {
      result {
        uuid
        type
        name,
        users {
          uuid
          nickname
          profileImage
          profileText
        }
        createDate
        messages {
          uuid
          userUuid
          roomUuid
          type
          contents
          filePath
          sendDate
          read
        }
        userNum
      }
      errCode
    }
  }
`;

/** 채팅방 정보 조회 */
export const GET_ROOM_INFO = gql`
  query ($roomUuid: String!) {
    getRoomInfo(
      roomUuid: $roomUuid
    ) {
      result {
        uuid
        type
        userUuid
        originName
        name
        maxUser
        isPrivate
        password
        users {
          uuid
          nickname
          profileImage
          profileText
        }
        leaveUsers {
          uuid
          nickname
          profileImage
          profileText
        }
        createDate
        blackList
        messages {
          uuid
          userUuid
          roomUuid
          type
          contents
          filePath
          sendDate
          read
        }
        userNum
      }
      errCode
    }
  }
`;

/** 채팅 메시지 추가 조회 */
export const GET_MESSAGES = gql`
  query ($roomUuid: String!, $lastChatDate: String!) {
    getMessages(
      roomUuid: $roomUuid
      lastChatDate: $lastChatDate
    ) {
      result {
        uuid
        userUuid
        roomUuid
        type
        contents
        sendDate
        read
      }
      errCode
    }
  }
`;

/** 채팅방 목록 조회 */
export const GET_ROOM_LIST = gql`
  query {
    getRoomList {
      result {
        roomList {
          uuid
          userUuid
          type
          name
          users {
            uuid
            nickname
            profileImage
          }
          maxUser
          isPrivate
          password
          createDate
          joinDate
          lastChat {
            uuid
            userUuid
            roomUuid
            type
            contents
            filePath
            sendDate
            read
          }
        }
        noReadNumJsonString
      }
    }
  }
`;

/** 채팅방 나가기 */
export const LEAVE_ROOM = gql`
  mutation ($roomUuid: String!, $userUuid: String, $blackList: Boolean) {
    leaveRoom (
      roomUuid: $roomUuid
      userUuid: $userUuid
      blackList: $blackList
    ) {
      result
      errCode
    }
  }
`;

/** 그룹채팅방 생성 */
export const CREATE_GROUP_CHAT_ROOM = gql`
  mutation ($users: [String]) {
    createRoom (
      type: group
      users: $users
    ) {
      result
      errCode
    }
  }
`;

/** 그룹채팅방 친구 초대 */
export const INVITE_USER = gql`
  mutation ($roomUuid: String!, $userUuid: [String]!) {
    inviteUser: enterRoom (
      type: "group"
      roomUuid: $roomUuid
      userUuid: $userUuid
    ) {
      result
      errCode
    }
  }
`;

/** 오픈 채팅방 생성 */
export const CREATE_OPEN_CHAT_ROOM = gql`
  mutation ($name: String, $maxUser: Int, $password: String, $isPrivate: Boolean) {
    createRoom (
      type: open
      name: $name
      maxUser: $maxUser
      password: $password
      isPrivate: $isPrivate
    ) {
      result
      errCode
    }
  }
`;

/** 오픈 채팅방 목록 검색 */
export const GET_OPEN_CHAT_ROOM_LIST = gql`
  query ($roomName: String!, $page: Int) {
    getOpenChatRoomList (
      roomName: $roomName
      page: $page
    ) {
      result {
        uuid
        userUuid
        type
        name
        users {
          profileImage
        }
        maxUser
        isPrivate
        password
        createDate
        lastChat {
          sendDate
        }
        userNum
      }
      errCode
    }
  }
`;

/** 오픈 채팅방 참가 */
export const ENTER_OPEN_CHAT_ROOM = gql`
  mutation ($roomUuid: String!, $userUuid: [String]!, $password: String) {
    enterRoom (
      type: "open"
      roomUuid: $roomUuid
      userUuid: $userUuid
      password: $password
    ) {
      result
      errCode
    }
  }
`;

/** 유저별 채팅방 이름 변경 */
export const RENAME_CHAT_ROOM = gql`
  mutation ($roomUuid: String!, $roomName: String) {
    renameChatRoom (
      roomUuid: $roomUuid
      roomName: $roomName
    ) {
      result
      errCode
    }
  }
`;

/** 오픈 채팅방 방장 위임 */
export const DELEGATE_ROOM_USER = gql`
  mutation ($roomUuid: String!, $userUuid: String!) {
    delegateRoomMaster (
      roomUuid: $roomUuid
      userUuid: $userUuid
    ) {
      result
      errCode
    }
  }
`;

/** 오픈 채팅방 설정 변경 */
export const CHANGE_OPEN_CHAT_ROOM_SETTINGS = gql`
  mutation ($roomUuid: String!, $name: String!, $maxUser: Int!, $password: String, $isPrivate: Boolean!) {
    changeRoomSettings (
      roomUuid: $roomUuid
      name: $name
      maxUser: $maxUser
      password: $password
      isPrivate: $isPrivate
    ) {
      result
      errCode
    }
  }
`;

/** 사진, 동영상, 오디오, 파일 채팅 메시지 전송 */
export const SEND_UPLOAD_CHAT = gql`
  mutation ($roomUuid: String!, $type: UploadType!, $files: [Upload]!, $compress: Boolean) {
    sendUploadChat (
      roomUuid: $roomUuid
      type: $type
      files: $files
      compress: $compress
    ) {
      result
      errCode
    }
  }
`;