import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import commonConfig from '@/config/common.json';

const isDevelopmentEnv = process.env.NODE_ENV === 'development';
export const backendUrl = isDevelopmentEnv ? commonConfig.backendUrl : '';

const apollo = new ApolloClient({
  link: createUploadLink({ uri: `${backendUrl}/gql` }),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: { fetchPolicy: 'no-cache' },
    mutate: { fetchPolicy: 'no-cache' },
    watchQuery: { fetchPolicy: 'no-cache' }
  }
});

export default apollo;