import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000075;
  backdrop-filter: blur(5px);
  transition: opacity 0.2s ease-in-out;
  user-select: none;
`;

export default Backdrop;