import { makeVar } from '@apollo/client';
import ReactiveVarState from '@/utils/ReactiveVarState';

export interface CommonVar {
  theme: ThemeMode
  lang: Language
}

const isLightMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: Light)').matches;

const initialState: CommonVar = {
  theme: isLightMode ? 'light' : 'dark',
  lang: 'ko'
};

const commonVar = makeVar<CommonVar>(initialState);

const commonState = new ReactiveVarState(commonVar, {
  key: 'common',
  persist: 'localStorage'
});

export const setTheme = (theme: ThemeMode) => commonState.set({
  ...commonVar(),
  theme
});

export const setLang = (lang: Language) => commonState.set({
  ...commonVar(),
  lang
});

export default commonState;