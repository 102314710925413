import { ApolloProvider } from '@apollo/client';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import Router from '@/Router';
import apollo from '@/utils/apolloClient';

const App = () => {
  return (
    <ApolloProvider client={apollo}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Router/>
      </LocalizationProvider>
    </ApolloProvider>
  );
};

export default App;